<template>
  <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-view-detail" hide-footer :title="title"  class="modal-contract-detail">
    <div class="d-block text-center">
      <ValidationObserver ref="form">
        <form class="form" >
          <div class="row">
            <div class="col-md-7">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Họ và tên</label>
                    <ValidationProvider name="Họ và tên" rules="required|min:6" v-slot="{ errors,classes }" vid="name">
                      <el-input type="text" name="name" placeholder="Họ và tên học viên"
                             :class="classes"
                             :value="contract_detail.customer? contract_detail.customer?.name : contract_detail.user?.name" disabled />
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Số điện thoại</label>
                    <ValidationProvider vid="phone" name="Số điện thoại"
                                        :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                        v-slot="{ errors,classes }">

                      <el-input type="number" disabled="disabled" :value="contract_detail.customer ? contract_detail.customer?.phone : contract_detail.user?.phone" :class="classes" />
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors,classes }">
                      <el-input type="email" name="email" placeholder="Email"
                             :class="classes"
                             :value="contract_detail.customer ? contract_detail.customer?.email : contract_detail.user?.email" disabled="disabled" />
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Ngày tháng năm sinh</label>
                    <el-date-picker id="birthDay"
                                    format="dd-MM-yyyy"
                                    ref="birthday"
                                    name="birthday"
                                    value-format="dd-MM-yyyy" type="date" placeholder="Ngày sinh"
                                    :value="contract_detail.customer ? contract_detail.customer?.ngay_sinh : contract_detail.user?.profile?.birthday"
                                    default-value="2000-10-10"
                                    style="width: 100%;"
                                    disabled
                    ></el-date-picker>
                  </div>
                </div>
              </div>
              <course-class v-for="(course_infor,index) in contract_detail.student_register_courses" :key="index"
                            :course_infor="course_infor"
                            :index="index"
                            :center_classes="center_classes"
                            :courses="courses"
                            :condition_courses="condition_courses"
                            :is-transferred="is_transferred"
                            :courses-refund="coursesRefund"
                            :disable="true"
                            @deleteCourse="deleteCourse"
                            @selectCourse="selectCourse"
                            @selectClass="selectClass"
              >
              </course-class>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Trung tâm:
                      <span class="text-danger"></span></label>
                    <el-input type="text" disabled="disabled" v-model="contract_detail.center.name" placeholder="Tên trung tâm" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Chi nhánh:
                      <span class="text-danger"></span></label>
                    <el-input type="text" disabled="disabled" :value="contract_detail.branch ? contract_detail.branch.name: ''" placeholder="Tên chi nhánh" />
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Hình thức đóng học phí:</label>
                    <input type="text" disabled="disabled" :value="contract_detail.tuition_payment ? contract_detail.tuition_payment.name : ''" placeholder="Hình thức đóng học phí" class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 mt-lg-0 mt-3">
              <!-- Khóa học đã chọn -->
              <div class="example-preview table-responsive">
                <h4 class="mb-3" style="font-weight: 700">Khóa học đã chọn</h4>
                <b-button v-b-toggle.list-chosen-courses class="m-1 m-0" id="button_show_value" @click="changeChervon">Chi tiết khóa học <i class="fas fa-chevron-down"></i></b-button>
                <b-collapse id="list-chosen-courses">
                  <table class="table table-vertical-center">
                    <tbody>
                    <tr v-for="(course_infor,index) in contract_detail.student_register_courses" :key="index">
                      <td>{{ course_infor.course.name }}</td>
                      <td width="25%">{{ coursePrice[index] | toThousandFilter }} ₫</td>
                      <td>{{ course_infor.course_sale}} %</td>
                      <td>{{ parseInt(course_infor.course_fee) * (1 - parseInt(course_infor.course_sale)/100) | toThousandFilter}} ₫</td>
                    </tr>
                    </tbody>
                  </table>
                </b-collapse>
                <div class="row pt-1">
                  <div class="col-md-6">
                    <strong class="pl-2">Tổng giá khóa (1)</strong>
                  </div>
                  <div class="col-md-6 text-right">
                    <strong>{{totalCourseRegisterFee | toThousandFilter }} ₫</strong>
                  </div>
                </div>
              </div>
              <!-- Chi tiết thanh toán -->
              <div class="payment-detail pt-5">
                <div>
                  <h4 class="mb-3" style="font-weight: 700">Chi tiết thanh toán</h4>
                </div>
                <b-button v-b-toggle.payment-contract-detail class="m-1 m-0  button_show_value" @click="changeChervonPromotion">Chi tiết khuyến mại <i class="fas fa-chevron-down"></i></b-button>
                <b-collapse id="payment-contract-detail">
                  <div class="row" v-if="vouchersForContract.length > 0" v-for="(voucher,index) in vouchersForContract" :key="index">
                    <div class="col-4">
                      <p>Voucher {{++index}}:</p>
                    </div>
                    <div class="col-4">
                      <span v-if="voucher.type !== 0" class="promotion-list"><strong>{{'Quy đổi quà tặng - ' + voucher.name }}</strong></span>
                      <span v-else class="promotion-list"><strong>{{voucher.name}}</strong></span>
                    </div>
                    <div class="col-4">
                      <strong class="text-danger" v-if="voucher.unit === 1 ">{{ voucher.value | toThousandFilter }} ₫</strong>
                      <strong class="text-danger" v-else>{{ voucher.value }} %</strong>
                    </div>
                  </div>
                </b-collapse>
                <div class="row border-custom p-3">
                  <div class="col-md-6 pb-2">
                    <strong>Tổng khuyến mại (2)</strong>
                  </div>
                  <div class="col-md-6 pb-2 text-right">
                    <strong>{{ totalDiscount | toThousandFilter }} ₫</strong>
                  </div>
                  <div class="col-md-6 pb-2" v-if="isWithdrawWithCourse">
                    <strong><span>Giá trị khóa bị hủy (3)</span>
                      <i
                          class="fas fa-exclamation-circle ml-3"
                          style="cursor: pointer; color: red"
                          @click="openModalListCourses()"
                          v-tooltip="'Danh sách khóa bị hủy'"
                      ></i>
                    </strong>
                  </div>
                  <div class="col-md-6 pb-2 text-right" v-if="isWithdrawWithCourse">
                    <strong>{{ totalCourseRefundFee | toThousandFilter }} ₫</strong>
                  </div>
                  <div class="col-md-6 pb-2" v-if="isWithdrawWithCourse || (!isWithdrawWithCourse && totalDestroyFee > 0)">
                    <strong>Phí hủy khóa
                      <span v-if="isWithdrawWithCourse">(4)</span>
                      <span v-if="!isWithdrawWithCourse">(3)</span>
                    </strong>
                  </div>
                  <div class="col-md-6 text-right" v-if="isWithdrawWithCourse || (!isWithdrawWithCourse && totalDestroyFee > 0)">
                    <strong>{{ totalDestroyFee | toThousandFilter }} ₫</strong>
                  </div>
                  <div class="col-md-6 pb-2" v-if="is_transferred">
                    <strong>Khuyến mại sau thay đổi</strong>
                  </div>
                  <div class="col-md-6 text-right" v-if="is_transferred">
                    <strong>{{ totalPromotionAfterTransferred | toThousandFilter }} ₫</strong>
                  </div>
                </div>
                <div class="row p-3">
                  <div class="col-md-6 pb-2">
                    <strong>Tổng giá trị hợp đồng
                      <span v-if="isWithdrawWithCourse">(5) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(5) = (1) - (2) - (3) + (4)'"></i></span>
                      <span v-if="!isWithdrawWithCourse && totalDestroyFee > 0">(4) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(4) = (1) - (2) + (3)'"></i></span>
                      <span v-if="!isWithdrawWithCourse && totalDestroyFee === 0">(3) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(3) = (1) - (2) '"></i></span>
                    </strong>
                  </div>
                  <div class="col-md-6 pb-2 text-right">
                    <strong v-if="isWithdrawWithCourse">{{ totalPriceContract | toThousandFilter }} ₫</strong>
                    <strong v-if="!isWithdrawWithCourse && totalDestroyFee > 0">{{ totalCourseRegisterFee - totalDiscount + totalDestroyFee | toThousandFilter }} ₫</strong>
                    <strong v-if="!isWithdrawWithCourse && totalDestroyFee === 0">{{ totalCourseRegisterFee - totalDiscount | toThousandFilter }} ₫</strong>
                  </div>

                  <div class="col-md-6 text-center">
                    <strong>Đã thanh toán
                      <span v-if="isWithdrawWithCourse">(6)</span>
                      <span v-if="!isWithdrawWithCourse && totalDestroyFee > 0">(5)</span>
                      <span v-if="!isWithdrawWithCourse && totalDestroyFee === 0">(4)</span>
                    </strong>
                  </div>
                  <div class="col-md-6 text-right">
                    <strong>{{ contract_detail.total_paid | toThousandFilter }} ₫</strong>
                  </div>

                  <div class="col-md-6 pt-2">
                    <strong>Còn nợ
                      <span v-if="isWithdrawWithCourse">
                        (7) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(7) = (5) - (6)'"></i>
                      </span>
                      <span v-if="!isWithdrawWithCourse && totalDestroyFee > 0">
                        (6) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(6) = (4) - (5)'"></i>
                      </span>
                      <span v-if="!isWithdrawWithCourse && totalDestroyFee === 0">
                        (5) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(5) = (3) - (4)'"></i>
                      </span>
                    </strong>
                  </div>
                  <div class="col-md-6 pt-2 text-right">
                    <strong v-if="isWithdrawWithCourse">{{ totalPriceContract - contract.total_paid | toThousandFilter }} ₫</strong>
                    <strong v-if="!isWithdrawWithCourse && totalDestroyFee > 0">{{ (totalCourseRegisterFee - totalDiscount + totalDestroyFee) - contract.total_paid | toThousandFilter }} ₫</strong>
                    <strong v-if="!isWithdrawWithCourse && totalDestroyFee === 0">{{ (totalCourseRegisterFee - totalDiscount) - contract.total_paid | toThousandFilter }} ₫</strong>
                  </div>
                </div>
              </div>
              <div v-if="is_edutalk && gift_select" class="payment-detail pt-5 table-responsive">
                <div>
                  <h4 class="mb-3" style="font-weight: 700">Quà tặng</h4>
                </div>
                <b-button v-b-toggle.payment-gift-detail class="m-1 m-0  button_show_value" @click="changeChervonPromotion">{{ gift_select.name }} <i class="fas fa-chevron-down"></i></b-button>
                <b-collapse id="payment-gift-detail">
                  <p v-html="gift_select?.description" class="mt-3 pl-2 ml-3 font-weight-bold"></p>
                </b-collapse>
              </div>
              <div class="payment-information">
                <payment-information :subcontracts="contract_detail.subcontracts" :refund-data="refundData" :gift-data="giftData" :is_edutalk="is_edutalk"></payment-information>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hideViewModal">Đóng</button>
    </footer>
    <list-course-refund :show-modal="showModalListCourses" @clicked="closeModalListCourses" :list-courses-refund="coursesRefund"></list-course-refund>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import CourseClass from "./CourseClass";
import {LIST_COURSE_REFUND} from "@/core/services/store/contract/contracts.module";
const _ = require('lodash');
import PaymentInformation from "./modal-contract/PaymentInformation";
import ListCourseRefund from "./modal-contract/ListCourseRefund";
import "@/assets/sass/contract/contractForm.scss";
import {GET_CLASSES} from "@/core/services/store/course/classes.module";
import {GET_COURSES} from "@/core/services/store/course/course.module";

export default {
  components: {
    Multiselect,
    CourseClass,
    PaymentInformation,
    ListCourseRefund,
  },
  props: {
    showModal: { type: Boolean,default: false },
    contract: {
      type : Object,
      default: () => { return {} }
    },
  },
  name: "ContractForm",
  data() {
    return {
      title: '',
      /* recalculate when action */
      recalculate : 1,
      recalculateSubTotal : 1,
      check_general: false,
      totalCourseRefundFee : 0,
      totalDestroyFee : 0,
      totalContractAmount : 0,
      totalWithdrawWithinCourse : 0,
      totalCourseRegisterFee : 0,
      totalDiscountByVoucher: 0,
      totalValueOfVoucher : 0,
      totalDiscount: 0,
      coursesRefund: [],
      coursePrice: [],
      refundData: [],
      giftData: [],
      isWithdrawWithCourse : false,
      /* detail variable */
      contract_detail : {
        customer : {
          ngay_sinh : ''
        },
        user : {
          id: '',
          name: '',
          phone: '',
          email: '',
          profile : {
            address : ''
          },
        },
        center : { name : ''},
        branch : { name : ''},
        student_register_courses : [{
          id: '',
          contract_id : '',
          course_id : '',
          class_id : '',
          course_fee : '',
          course_sale : '',
          dang_ky_ho : '',
          xac_nhan : '',
          waiting_status : '',
          center_class : {
            id: '',
            course_id : '',
            name: ''
          },
          course : {
            id : '',
            name: '',
            fee: '',
            sale: '',
          },
        }],
      },
      /* list variable */
      promotions : [],
      vouchers : [],
      center_classes: [],
      courses: [],
      /* list variable of contract */
      promotionForContract: '',
      vouchersForContract: [],
      condition_courses : [],
      showModalListCourses: false,
      is_transferred: false,
      totalPromotionAfterTransferred: 0,
      gift_select : null,
      is_edutalk : false
    }
  },
  computed: {
    totalPriceContract(){
      return this.totalCourseRegisterFee - this.totalDiscount - this.totalCourseRefundFee + this.totalDestroyFee;
    }
  },
  mounted() {
    this.clearForm();
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.title = "Thông tin chi tiết hợp đồng";
        this.$refs['modal-view-detail'].show();
        this.is_nap_rut_form = false;
        if (this.contract) {
          this.gift_select =this.contract?.gift_select;
          if (this.gift_select) {
            this.gift_select.description = this.gift_select.description?.replace(/\n/g, '<br>');
          }
          this.listCourseRefund();
          this.getCourses();
          this.getClasses();
          // set các giá trị cho việc hiển thị
          this.contract_detail = this.contract;
          if (this.contract.center && this.contract.center.is_edutalk === 1) {
            this.is_edutalk = true
          }
          this.contract_detail.user.profile.birthday = this.contract_detail.user.profile.birthday ?
              this.contract_detail.user.profile.birthday.split("-").reverse().join("-") : '';
          this.promotionForContract = this.contract.promotion ? this.contract.promotion : '';
          this.vouchersForContract = this.contract.vouchers ? this.contract.vouchers : [];
          this.id_voucher_disable = _.reduce(this.contract.vouchers,function (result,value,key){
            result.push(value.id);
            return result
          },[]);
          this.id_promotion_disable = this.contract.promotion?.id | '';

          this.refundData = this.contract_detail.log_refund.filter((item) => {
            return item.type_refund === 4;
          });
          this.giftData = this.contract_detail.log_refund.filter( (item) => {
            return item.type_refund === 3;
          });
          this.contract_detail.user.profile.birthday = this.contract_detail.user.profile.birthday ?
              this.contract_detail.user.profile.birthday.split("-").reverse().join("-") : '';
          const subcontractsCourse = this.contract_detail.subcontracts.filter((item) => item.type === 1);
          let arrCourse = [];
          let courseWithDraw = subcontractsCourse.map(item => item.courses_relation).map(item => item.map(item => item.id));
          courseWithDraw.forEach(item => { item.forEach(value => arrCourse.push(value)) })
          arrCourse = [...new Set(arrCourse)];
          let courseInCourseStudent = [];
          this.contract.student_register_courses.forEach((item) => {
            if (arrCourse.includes(item.course_id)) {
              courseInCourseStudent.push(item);
            }
          });
          const subcontractsWithinCourse = this.contract_detail.subcontracts.filter((item) => item.type === 2);
          this.totalCourseRefundFee = subcontractsCourse.reduce((result, subcontract) => result + subcontract.value, 0);
          this.WithdrawWithCourse = subcontractsWithinCourse.reduce((result, subcontract) => result + subcontract.value, 0);
          //chuyển nhượng
          this.is_transferred = this.contract_detail.from_course_transfers.length > 0;
          //nếu là chuyển nhượng
          if (this.is_transferred){
            this.totalCourseRefundFee = courseInCourseStudent.reduce((result, item) => result + (item.course_fee *(100 - item.course_sale)/ 100)- item.promotion_fee, 0);
            this.totalPromotionAfterTransferred = this.contract_detail.total - this.contract_detail.total_after_voucher;
          } else { // rút tiền bth
            this.totalCourseRefundFee = courseInCourseStudent.reduce((result, item) => result + (item.course_fee *(100 - item.course_sale)/ 100), 0);
          }
          this.totalDestroyFee = this.contract_detail.subcontracts.reduce((result, subcontract) => result + subcontract.destroy_fee, 0);
          this.totalContractAmount = this.contract_detail.total_after_voucher;
          this.totalCourseRegisterFee = this.contract.student_register_courses.reduce((result, item) => { return result + (item.course_fee *(100 - item.course_sale)/ 100)}, 0);
          this.coursePrice = this.contract.student_register_courses.map((item) => item.course_fee);
          this.promotionForContract = this.contract.vouchers.filter(item => item.type !== 0).shift();
          let vouchersForContract = [];
          if(this.promotionForContract) {
            vouchersForContract.push(this.promotionForContract)
          }
          this.vouchersForContract = this.contract.vouchers.filter(item => item.type === 0);
          this.contract.vouchers.filter(item => {
            if(item.type === 0) {
              vouchersForContract.push(item);
            }
          });
          this.vouchersForContract = vouchersForContract;
          this.calculateTotalValueForVoucher();
          let valuePromotion = this.promotionForContract ? this.promotionForContract.value : 0;
          // this.totalDiscount = valuePromotion + this.totalValueOfVoucher + (this.contract_detail.total * this.totalDiscountByVoucher/100);
            this.totalDiscount = this.totalValueOfVoucher + (this.totalCourseRegisterFee * this.totalDiscountByVoucher/100);
          this.condition_courses = _.reduce(this.contract.student_register_courses, function (result, value, key){
            result.push({id : value.course.id, name: value.course.name, fee: value.course.fee, sale: value.course.sale,
            });
            return result
          },[]);
        }
      }
    },
  },
  methods: {
    onHide(evt) {
      this.$emit("clicked", true);
    },
    clearForm() {
      this.title = '';
    },
    hideViewModal() {
      this.$refs['modal-view-detail'].hide();
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs['modal-view-detail'].toggle('#toggle-btn');
    },
    selectClass : function ($event){
      console.log($event)
    },
    selectCourse : function ($event){
      this.recalculate++;
    },
    openModalListCourses : function (){
      this.showModalListCourses = true;
    },
    closeModalListCourses : function (){
      this.showModalListCourses = false;
    },
    deleteCourse(index){
      this.recalculate ++;
      this.contract_detail.student_register_courses.splice(index,1);
    },
    getClasses: function (){
      this.$store.dispatch(GET_CLASSES,{ limit: 10000, status: 1}).then((res)=>{
        this.center_classes = _.map(res.data.data,(item) => {
          item = {id: item.id, name: item.name, course_id : item.course_id};
          return item;
        })
      });
    },
    getCourses: function (){
      this.$store.dispatch(GET_COURSES, {
        limit:10000,
        status: 1,
        center_id: this.center_id,
        all: 1
      }).then((res)=>{
        this.courses = _.map(res.data,(item) => {
          item = { id: item.id, name: item.name , fee: item.fee, sale: item.sale};
          return item;
        })
      });
    },
    listCourseRefund: function (){
      this.$store.dispatch(LIST_COURSE_REFUND, {id : this.contract.id})
          .then((res) => {
            this.coursesRefund = res.data;
            this.isWithdrawWithCourse = this.coursesRefund.length > 0;
          });
    },
    changeChervon(){
      $('#button_show_value').children('.fas').toggleClass('fa-chevron-up fa-chevron-down');
    },
    changeChervonPromotion(){
      $('.button_show_value').children('.fas').toggleClass('fa-chevron-up fa-chevron-down');
    },
    calculateTotalValueForVoucher(){
      this.totalDiscountByVoucher = _.reduce(this.vouchersForContract, function (result,value,key){
        if (value.unit === 0){
          result += value.value;
        }
        return result;
      },0);
      this.totalValueOfVoucher = _.reduce(this.vouchersForContract, function (result,value,key){
        if (value.unit === 1){
          result += value.value;
        }
        return result;
      },0);
    },
  },

}
</script>

<style>
.list-courses{
  position: relative;
}
.delete-course{
  position: absolute;
  left: 5px;
  top: 38px;
  font-size: 10px;
  cursor: pointer;
  z-index: 10;
}
.promotion-img {
  width: 100%;
  height: 57px;
}

.promotion-icon {
  position: relative;
}

body .delete-promotion-contract {
  position: absolute;
  left: -35px;
  top: 0px !important;
  width: 26px;
  height: 26px;
}

.fa-minus-circle:hover {
  color: red !important;

}

.promotion-list {
  position: relative;
}

.promotion-count{
  position: absolute;
  background-color: red;
  border-radius: 50%;
  top: -7px;
  right: -17px;
  width: 16px;
  height: 16px;
  color: #fff;
  text-align: center;
}

.nap_rut_all_form  .card-body{
  padding: 15px;
}

</style>
